* {
  padding: 0;
  margin: 0;
  color: #000;
  font-size: 15px;
  font-family: '微软雅黑';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  border: 0px;
}
input::-webkit-input-placeholder {
  color: #ccc;
}
input::-moz-input-placeholder {
  color: #ccc;
}
input::-ms-input-placeholder {
  color: #ccc;
}
input:focus {/*输入框获得焦点时，外边框样式*/
  outline-width: 0px;
  outline-color: green;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
::-webkit-scrollbar{
  /*display:none;*/
  width: 0px;
  height: 13px;
  background-color: #C0C0C0;
}
::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb{
  /*width: 10px;*/
  height: 20px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #A9A9A9;
}
/* *{
  scrollbar-width: none;
  -ms-overflow-style: none;
} */

@font-face {
  font-family: "iconfont";
  src: url("./images/fontIcons/iconfont.woff2") format("woff2"),
    url("./images/fontIcons/iconfont.woff") format("woff"),
    url("./images/fontIcons/iconfont.ttf") format("truetype");
}
.iconfontIndexCss{
  font-family:"iconfont" !important;
  font-size:14px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}