html, body{font-family:Helvetica,"Microsoft YaHei",Arial,sans-serif;}
/*table样式*/
.table-bordered {  border: 1px solid #ddd; }
.table { width: 100%;  max-width: 37%; margin-bottom: 20px; }
table { background-color: transparent; }
table { border-spacing: 0;  border-collapse: collapse; }
.table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
 border: 1px solid #ddd; text-align: center}
.table>thead>tr>th { vertical-align: bottom;  border-bottom: 2px solid #ddd; text-align: center}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
 padding: 8px; line-height: 1.42857143;  vertical-align: top; border-top: 1px solid #ddd;text-align: center }
.rightDiv{float: left;    float: left; padding-left: 16px; margin-top: 1px;}

.paginationDiv {display: block; color: #535353; line-height: 29px; text-align: center;}
.paginationDiv a {float: left; width: 25px; height: 30px; padding: 0 7px; vertical-align: middle; text-decoration: none; cursor: pointer;}
.paginationDiv .next,.paginationDiv .prev {background-color: #f3f4f8; border: 1px solid #e5e5e5;}
.paginationDiv .next:hover,.paginationDiv .prev:hover {background-color: #ddd; border: 1px solid #ddd;}
.paginationDiv .next:before,.paginationDiv .prev:before {content: '<'; font-size: 14px }
.paginationDiv .disable.next,.paginationDiv .prev.disable {background-color: #f3f4f8; border-color: #e5e5e5; cursor: no-drop;}
.paginationDiv .disable.next:before,.paginationDiv .prev.disable:before {color: #a0a0a0;border-color: #337ab7 }
.paginationDiv .next:before {content: '>'}
.paginationDiv .num {margin-left: -1px; padding: 0 7px; border: 1px solid #e5e5e5; color: #707070 }
.paginationDiv .num.current{background-color: #337ab7; border-color: #337ab7; color: #fff }
.paginationDiv .num:hover {background-color: #ddd; border-color: #ddd; color: #1a1a1a }
.paginationDiv .ellipsis {width: 42px; height: 30px; color: #707070; line-height: 30px }
.paginationDiv input {width: 44px; height: 24px; border: 1px solid #eee; text-align: center }
